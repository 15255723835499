import moment from 'moment';
import paymentService from '../firebase/firestore/payments';
import fieldValue from '../firebase/firestore/fieldValue';
import store from '../store';

const { errorMsg } = require('../static/errorMessage');

const PROMO_TYPE = {
  STATIC: 1,
  PERCENTAGE: 2,
};

const applyPromotion = code => paymentService.findPromotion(code)
  .then(promoDoc => promoDoc)
  .catch((error) => {
    store.dispatch('setError', errorMsg['connection/unavailable']);
    throw error;
  });

const updatePromoHist = async (code, uid) => {
  const promoHist = await paymentService.getPromoHist(code);
  const data = {
    code,
    used: promoHist && promoHist.used ? fieldValue.increment(1) : 1,
    uids: promoHist && promoHist.uids ? fieldValue.arrayUnion(uid) : [uid],
  };
  if (promoHist) {
    await paymentService.updatePromoHist(data);
  } else {
    await paymentService.setPromoHist(data);
  }
};

const createOrder = od => new Promise(async (resolve, reject) => {
  const yymm = await moment(paymentService.fbTime().toDate()).format('YYMM');
  const lastOrder = await paymentService.getLastOrder();
  let lastId = lastOrder.id;
  const lastMoment = lastId.toString().substring(0, 4);
  if (yymm === lastMoment) {
    lastId += 1;
  } else {
    lastId = `${yymm}00001`;
  }
  const order = {
    id: parseInt(lastId, 10),
    ...od,
  };
  paymentService.createPurchaseOrder(order)
    .then(() => {
      resolve(`OD${lastId}`);
    })
    .catch((e) => {
      reject(e);
    });
});

export default {
  PROMO_TYPE,
  applyPromotion,
  updatePromoHist,
  createOrder,
};
